import st from "@styles/components/IntercomCustomButton.module.scss";
import HeadsetIcon from "@assets/icons/general/HeadsetIcon";
import { show } from "@intercom/messenger-js-sdk";
export default function IntercomCustomButton({
  chatOpen
}) {
  const showIntercomChat = () => {
    show();
  };
  return <div className={`${st["intercom-custom-button-container"]} ${chatOpen && st["chat-open"]}`} onClick={showIntercomChat} data-sentry-component="IntercomCustomButton" data-sentry-source-file="index.jsx">
      <HeadsetIcon data-sentry-element="HeadsetIcon" data-sentry-source-file="index.jsx" />
    </div>;
}